<script>
import Football from '@/services/Football';
import Swal from "sweetalert2";

export default {
  props: {
    fixture: Object,
  },

  data() {
    return {
      csrf_token: localStorage.getItem('csrf_token'),
      submitted: false,
      showModal: false,
      tryingToEdit: false,
      preview_text: '',  // Initialize as an empty string
      id: this.$route.params.id,
    };
  },
  mounted() {

    this.initializePreviewText();
  },
  watch: {
    fixture: {
      immediate: true,
      handler() {
        this.initializePreviewText();
      }
    }
  },
  methods: {
    initializePreviewText() {
      if (this.fixture && this.fixture.preview) {
        this.preview_text = this.fixture.preview.preview_text;
      }
    },
    closeModal() {
      this.showModal = false;
    },
    refreshData() {
      this.$emit('onRefresh');
    },
    changePreviewText() {
      this.tryingToEdit = true;
      Football.createMatchPreviewText(this.fixture.match_id, {
        csrf_token: this.csrf_token,
        text: this.preview_text
      })
          .then((response) => {
            this.tryingToEdit = false;
            const res = response.data.message;
            if (res) {
              this.successmsg("Match preview text successfully changed!");
              this.refreshData();
              this.closeModal();
            } else {
              this.failedmsg("Failed to change match preview text!");
            }
          })
          .catch(error => {
            this.tryingToEdit = false;
            Swal.fire("Fail!", error.message || "Something went wrong", "warning");
          });
    }
  }


};
</script>


<template>
  <b-modal v-model="showModal" id="edit_preview_text" size="xl" centered>
    <template v-slot:modal-header>
      <div class="d-flex w-100 justify-content-between align-items-center">
        <!-- Left Title -->
        <h5 class="modal-title mb-0">Edit Preview Text</h5>

        <!-- Right Title -->
        <h6 class="modal-title mb-0 text-right" v-if="fixture.preview && fixture.preview.preview_ai">AI generated</h6>
      </div>
    </template>

    <form @submit.prevent="changePreviewText">
      <b-form-group label="" label-for="formrow-amount-input" class="mb-3">
        <textarea v-model="preview_text" class="w-100 modal-form border border-light p-2" placeholder="Add preview text" rows="10"></textarea>
      </b-form-group>
      <input type="hidden" name="csrf_token" v-model="csrf_token">
    </form>

    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="changePreviewText" :disabled="tryingToEdit">
        <b-spinner v-show="tryingToEdit" small></b-spinner>
        Save
      </b-button>
    </template>
  </b-modal>
</template>





